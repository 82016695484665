const countryMap = {
    'GB': 'hreflang_url',
    'IN': ''
}

export const getCountryValue = () => {
    const country = typeof window != "undefined" ? window["__usercountry__"] : 'IN';
    return country;
}

export const getCountryWiseAttribute = (value) => {
    const country =  value || getCountryValue();
    return countryMap.hasOwnProperty(country) ? countryMap[country] : 'redirect_url'
}

export const getDataFronSession = (keyName) => {
    const dataFromStorage = sessionStorage.getItem(keyName) 
    
    if(dataFromStorage){
        return dataFromStorage;
    }

    return null;
}