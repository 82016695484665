import { useEffect, useState } from "react";
import { getDataFronSession } from "../services/helpers/getCountryData";
import { useRouter } from "next/router";

export const useNotifyModal = ({country}) => {
    const router = useRouter();
    const [showNotifyModal, setShowNotifyModal] = useState(false);

    const handleModalClose = () => {
        setShowNotifyModal(false);
        sessionStorage.setItem('notifyPopup', '1');
    }

    useEffect(() => {
        const notifyPopupOpened = getDataFronSession('notifyPopup');
        if(country != 'IN' && !notifyPopupOpened){
            setShowNotifyModal(true);
        }
    },[router.asPath])


    return {
        showNotifyModal,
        handleModalClose
    }
}